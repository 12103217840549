export enum VENDOR_LOCATION_COMPARISON_FILTERS {
  VENDOR_NAME = 'Vendor Name',
  VENDOR_CODE = 'Vendor Code',
  LOCATION_NAME = 'Location Name',
  LOCATION_CODE = 'Location Code',
  AIRPORT_CODE = 'Airport Code',
  LOCATION_STATUS = 'Status'
}

export enum VENDOR_LOCATION_HOURS_FILTERS {
  SEQUENCE = 'Sequence',
  HOURS_TYPE = 'Hours Type',
  SCHEDULE_TYPE = 'Schedule Type',
  START_DATE = 'Start Date',
  END_DATE = 'End Date',
  IS_24_HOURS = 'Is 24 Hours',
  START_TIME_LOCAL = 'Start Time Local',
  END_TIME_LOCAL = 'End Time Local',
  INCLUDE_HOLIDAYS = 'Include Holidays',
  STATUS = 'Status',
  ACCESS_LEVEL = 'Access Level'
}

export enum VENDOR_LOCATION_DATA_FILTERS {
  VENDOR_NAME = 'Vendor Name',
  VENDOR_CODE = 'Vendor Code',
  LOCATION_NAME = 'Location Name',
  LOCATION_CODE = 'Location Code',
  LOCATION_STATUS = 'Status',
}

export enum RECURRENCE_PATTERN_TYPE {
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
  RELATIVE_MONTHLY = 4,
  YEARLY = 5,
  RELATIVE_YEARLY = 6,
}

