import { auditFields } from '@wings/shared';

export const fields = {
  ...auditFields,
  id: {
    label: 'Id',
  },
  vendorLocationId: {
    label: 'Vendor Location Id',
  },
  a2GLocationType:{
    label: 'Air2Ground location Type*',
    rules: 'required'
  },
  isA2GCommCopy:{
    label: 'A2G Comm Copy',
  },
  locationDocUri:{
    label: 'A2G location PDF',
  },
  arrivalLogistic:{
    label: 'A2G Arrival Logistics',
    rules: 'max:1000'
  },
  departureLogistic:{
    label: 'A2G Departure Logistics',
    rules: 'max:1000'
  },
  a2GEmail:{
    label: 'Air2Ground Email',
  },
}