import { SETTING_ID } from '../Modules/Shared';
import { apiUrls } from './API.url';

const SettingsUrlMapper = {
  [SETTING_ID.SETTINGS_VENDOR_STATUS]:apiUrls.vendorStatus,
  [SETTING_ID.LOCATION_STATUS]:apiUrls.vendorLocationStatus,
  [SETTING_ID.SETTINGS_PARAMETERS]:apiUrls.vendorSettingsParameter,
  [SETTING_ID.SETTINGS_UNITS]:apiUrls.vendorSettingsUnits,
  [SETTING_ID.SETTINGS_HANDLING_FEES]:apiUrls.vendorSettingsHandlingFee,
  [SETTING_ID.SETTINGS_CURRENCY]:apiUrls.vendorSettingsCurrency,
  [SETTING_ID.SETTINGS_SERVICE_ITEM_NAME]:apiUrls.vendorSettingsServiceItemName,
  [SETTING_ID.SETTINGS_SERVICE_CATEGORY]:apiUrls.vendorSetingsServiceCategory,
  [SETTING_ID.SETTINGS_PRICING_STATUS]:apiUrls.vendorSettingsStatus,
  [SETTING_ID.SETTING_CONTACT_METHOD]:apiUrls.vendorContactMethod,
  [SETTING_ID.SETTING_CONTACT_TYPE]:apiUrls.vendorContactType,
  [SETTING_ID.SETTINGS_CONTACT_STATUS]:apiUrls.vendorContactStatus,
  [SETTING_ID.SETTING_ACCESS_LEVEL]:apiUrls.vendorAccessLevel,
  [SETTING_ID.SETTING_COMMUNICATION_SERVICE]:apiUrls.vendorContactCommunicationService,
  [SETTING_ID.SETTING_USAGES_TYPE]:apiUrls.vendorContactUsageType,
  [SETTING_ID.SETTING_ADDRESS_TYPE]:apiUrls.vendorAddressType,
  [SETTING_ID.SETTING_DOCUMENT_NAME]:apiUrls.vendorDocumentName,
  [SETTING_ID.SETTING_DOCUMENT_STATUS]:apiUrls.vendorDocumentStatus,
  [SETTING_ID.SETTINGS_VENDOR_LEVEL]:apiUrls.vendorLevel,
  [SETTING_ID.SETTINGS_CERTIFIED_MEMBER_FEE_SCHEDULE]:apiUrls.certifiedMemberFeeSchedule,
  [SETTING_ID.SETTINGS_PAYMENTS_OPTIONS]:apiUrls.paymentOptions,
  [SETTING_ID.SETTINGS_CREDIT_AVAILABLE]:apiUrls.creditAvailable,
  [SETTING_ID.SETTINGS_MAIN_SERVICE_OFFERED]:apiUrls.mainServicesOffered,
  [SETTING_ID.SETTINGS_OPERATON_TYPE]:apiUrls.operationType,
  [SETTING_ID.SETTINGS_HOURS_TYPE]:apiUrls.hoursType,
  [SETTING_ID.SETTINGS_HOURS_SCHEDULE_TYPE]:apiUrls.scheduleType,
  [SETTING_ID.SETTINGS_HOURS_STATUS]:apiUrls.status,
  [SETTING_ID.SETTING_A2G_LOCATION_TYPE]:apiUrls.a2GLocationType,
  [SETTING_ID.SETTING_APPROVAL_STATUS]:apiUrls.approvalStatus,
  [SETTING_ID.SETTING_DRIVER_LOCATION_CREW]:apiUrls.pickUpLocation,
  [SETTING_ID.SETTING_DRIVER_LOCATION_PAX]:apiUrls.pickUpLocation,
  [SETTING_ID.SETTING_AMENITIES]:apiUrls.amenities,
  [SETTING_ID.SETTING_AIRCRAFT_PARKING_OPTIONS]:apiUrls.aircraftParkingOptions,
  [SETTING_ID.SETTING_AIRCRAFT_PARKING_DISTANCE_FBO]:apiUrls.aircraftParkingDistanceFBO,
  [SETTING_ID.SETTING_AIRCRAFT_SPOT_ACCOMMODATION]:apiUrls.aircraftSpotAccommodation,
  [SETTING_ID.SETTING_TOWBAR_SCENARIOS]:apiUrls.towbarScenarios,
  [SETTING_ID.SETTING_INTERNATIONAL_DEPARTURE_PROCEDURES]:apiUrls.internationalDepartureProcedures,
  [SETTING_ID.SETTING_INTERNATIONAL_ARRIVAL_PROCEDURES]:apiUrls.internationalArrivalProcedures,
  [SETTING_ID.SETTING_AVAILABLE_FACILITIES]:apiUrls.availableFacilities,
  [SETTING_ID.SETTING_LUGGAGE_HANDLING]:apiUrls.luggageHandling,
  [SETTING_ID.SETTING_ARRIVAL_CREW_PAX_PASSPORT_HANDLING]:apiUrls.arrivalCrewPaxPassportHandling,
  [SETTING_ID.SETTING_DISABILITY_ACCOMMODATIONS]:apiUrls.disabilityAccommodations,
  [SETTING_ID.SETTING_HANGER_AVAILABLE_UOM]:apiUrls.hangerAvailableUom,
  [SETTING_ID.SETTING_ORDER_MANAGEMENT_SOFTWARE]:apiUrls.orderManagementSoftware,
}

const SettingNamesMapper = {
  [SETTING_ID.SETTING_ACCESS_LEVEL]:'Access Levels',
  [SETTING_ID.SETTINGS_VENDOR_STATUS]:'VendorStatus',
  [SETTING_ID.LOCATION_STATUS]:'LocationStatus',
  [SETTING_ID.SETTINGS_PARAMETERS]:'Parameter',
  [SETTING_ID.SETTINGS_UNITS]:'Unit',
  [SETTING_ID.SETTINGS_SERVICE_ITEM_NAME]:'Service Item',
  [SETTING_ID.SETTING_CONTACT_METHOD]:'Contact Method',
  [SETTING_ID.SETTING_CONTACT_TYPE]:'Contact Type',
  [SETTING_ID.SETTINGS_CONTACT_STATUS]:'Contact Status',
  [SETTING_ID.SETTING_COMMUNICATION_SERVICE]:'Communication Service',
  [SETTING_ID.SETTING_USAGES_TYPE]:'Usage Type',
  [SETTING_ID.SETTING_ADDRESS_TYPE]:'Address Type',
  [SETTING_ID.SETTING_DOCUMENT_NAME]:'Document Name',
  [SETTING_ID.SETTING_DOCUMENT_STATUS]:'Document Status',
  [SETTING_ID.SETTINGS_VENDOR_LEVEL]:'Vendor Level',
  [SETTING_ID.SETTINGS_CERTIFIED_MEMBER_FEE_SCHEDULE]:'Certified Member Fee Schedule',
  [SETTING_ID.SETTINGS_PAYMENTS_OPTIONS]:'Payment Options',
  [SETTING_ID.SETTINGS_CREDIT_AVAILABLE]:'Credit Available',
  [SETTING_ID.SETTINGS_MAIN_SERVICE_OFFERED]:'Main Services Offered',
  [SETTING_ID.SETTINGS_OPERATON_TYPE]:'Operation Type',
  [SETTING_ID.SETTINGS_HOURS_TYPE]:'Hours Type',
  [SETTING_ID.SETTINGS_HOURS_SCHEDULE_TYPE]:'Hours Schedule Type',
  [SETTING_ID.SETTINGS_HOURS_STATUS]:'Status',
  [SETTING_ID.SETTING_A2G_LOCATION_TYPE]:'A2G Location Type',
  [SETTING_ID.SETTING_APPROVAL_STATUS]:'Approval Status',
  [SETTING_ID.SETTING_DRIVER_LOCATION_CREW]:'Driver Pick Up Location Type - CREW',
  [SETTING_ID.SETTING_DRIVER_LOCATION_PAX]:'Driver Pick Up Location Type - PAX',
  [SETTING_ID.SETTING_AMENITIES]:'Amenities',
  [SETTING_ID.SETTING_AIRCRAFT_PARKING_OPTIONS]:'Aircraft Parking Options',
  [SETTING_ID.SETTING_AIRCRAFT_PARKING_DISTANCE_FBO]:'Aircraft Parking Distance from FBO',
  [SETTING_ID.SETTING_AIRCRAFT_SPOT_ACCOMMODATION]:'Aircraft Spot Accommodation',
  [SETTING_ID.SETTING_TOWBAR_SCENARIOS]:'Towbar Scenarios',
  [SETTING_ID.SETTING_INTERNATIONAL_DEPARTURE_PROCEDURES]:'International Departure Procedure',
  [SETTING_ID.SETTING_INTERNATIONAL_ARRIVAL_PROCEDURES]:'International Arrival Procedure',
  [SETTING_ID.SETTING_AVAILABLE_FACILITIES]:'Available Facilities',
  [SETTING_ID.SETTING_LUGGAGE_HANDLING]:'Luggage Handling',
  [SETTING_ID.SETTING_ARRIVAL_CREW_PAX_PASSPORT_HANDLING]:'Arrival Crew/Pax Passport Handling',
  [SETTING_ID.SETTING_DISABILITY_ACCOMMODATIONS]:'Disability Accommodations',
  [SETTING_ID.SETTING_HANGER_AVAILABLE_UOM]:'Hanger Available UOM',
  [SETTING_ID.SETTING_ORDER_MANAGEMENT_SOFTWARE]:'Order Management Software List',
}

export { SettingsUrlMapper, SettingNamesMapper };
