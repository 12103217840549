import React, { FC, useEffect, useState } from 'react';
import { GridPagination, IAPIGridRequest, IAPIPageResponse, UIStore } from '@wings-shared/core';
import { 
  BaseStore, 
  UltimateOwnershipStore, 
  VendorLocationStore
} from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { finalize, takeUntil } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { VIEW_MODE } from '@wings/shared';
import { forkJoin } from 'rxjs';
import { SettingBaseModel, UltimateOwnershipModel, VendorLocationModel } from '../../../Shared';

import {
  useAgGrid,
  useGridState,
} from '@wings-shared/custom-ag-grid';
import UltimateOwnershipCore from '../../../Shared/UltimateOwnershipCore/UltimateOwnershipCore';
import { IAPIResponseOwnership } from '../../../Shared/Interfaces/Response/API-Response-Ownership';
import { COLLECTION_NAMES } from '../../../Shared/Enums/CollectionName.enum';
import { useParams } from 'react-router';

interface Props {
  ultimateOwnershipStore: UltimateOwnershipStore;
  viewMode: VIEW_MODE;
  vendorLocationId: number;
  vendorLocationStore: VendorLocationStore;
}

const UltimateOwnership: FC<Props> = ({ 
  vendorLocationId,
  ultimateOwnershipStore,
  vendorLocationStore 
}) => {
  const unsubscribe = useUnsubscribe();
  const gridState = useGridState();
  const params = useParams();

  const agGrid = useAgGrid<[], UltimateOwnershipModel>([], gridState);
  const [ selectedVendorLocation, setSelectedVendorLocation ] = useState(new VendorLocationModel());

  useEffect(() => {
    loadVendorLocationData();
  }, []);

  const loadVendorLocationData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(parseInt(params.id))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        setSelectedVendorLocation(response);
      });
  };

  const upsertVendorLocationOwner = (rowIndex: number): void => {
    gridState.setIsProcessing(true);
    gridState.gridApi.stopEditing();
    const model = agGrid._getTableItem(rowIndex);
    UIStore.setPageLoader(true);
    ultimateOwnershipStore
      ?.upsertVendorLocationOwner([ model.serialize(0, vendorLocationId) ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
          gridState.setIsProcessing(false);
          gridState.setIsAllRowsSelected(false);
        })
      )
      .subscribe({
        next: (response: UltimateOwnershipModel) => {
          response = UltimateOwnershipModel.deserialize(response);
          agGrid._updateTableItem(rowIndex, response);
          agGrid.filtersApi.resetColumnFilters();
        },
        error: error => {
          agGrid._startEditingCell(rowIndex, 'name');
          BaseStore.showAlert(error.message, model.id);
        },
      });
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    UIStore.setPageLoader(true);
    gridState.setIsProcessing(true);
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
    };
    const ownershipRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'Id',
          propertyValue: vendorLocationId,
        },
      ]),
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
    };
    forkJoin([
      ultimateOwnershipStore?.getVendorOwner(ownershipRequest, COLLECTION_NAMES.VENDLOR_LOCATION),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false)
          gridState.setIsProcessing(false);
        })
      )
      .subscribe((response: [IAPIPageResponse<IAPIResponseOwnership>, SettingBaseModel]) => {
        response[0].totalNumberOfRecords=response[0].results.length
        gridState.setPagination(new GridPagination({ ...response[0] }));
        const results = UltimateOwnershipModel.deserializeList(response[0].results);
        gridState.setGridData(results);
        agGrid.reloadColumnState();
        agGrid.refreshSelectionState();
      });
  };

  return (
    <UltimateOwnershipCore
      rightContentActionText="Add Owner"
      loadInitialData={(pageRequest) => loadInitialData(pageRequest)}
      loadVendorData={()=> loadVendorLocationData()}
      onSave={(rowIndex) => upsertVendorLocationOwner(rowIndex)}
      ultimateOwnershipStore={ultimateOwnershipStore}
      backNavLink={vendorLocationStore.getVendorLocationBackNavLink(params)}
      backNavTitle="Vendor Location"
      headerName={selectedVendorLocation.label}
      agGrid={agGrid}
      gridState={gridState}
    />
  );
};
export default inject(
  'ultimateOwnershipStore','vendorLocationStore'
)(observer(UltimateOwnership));
