import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    editorWrapperContainer: {
      overflow: 'auto',
      // height:'100%',
      height:'calc(100vh - 150px)',
      '& .MuiCollapse-entered':{
        paddingBottom:'50px'
      }
    },
    headerActions: {
      justifyContent: 'space-between',
      '& button':{
        backgroundColor:'#005295!important',
        color:'#ffffff',
        '&:hover':{
          backgroundColor:'#003968!important'
        }
      },
      '& .MuiTypography-h6':{
        width: '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordWrap: 'normal',
        whiteSpace: 'nowrap'
      }
    }
  });