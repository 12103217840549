export enum VENDOR_CONTACT_COMPARISON_FILTERS {
    CONTACT_METHOD = 'Contact Method',
    CONTACT_NAME = 'Contact Name',
    TITLE = 'Title',
    CONTACT = 'Contact',
    CONTACT_TYPE = 'Contact Type',
    CONTACT_STATUS = 'Status',
    CONTACT_ACCESS_LEVEL = 'Access Level',
}

export enum VENDOR_LOCATION_CONTACT_COMPARISON_FILTERS {
    CONTACT_METHOD = 'Contact Method',
    CONTACT_NAME = 'Contact Name',
    CONTACT_LOCATION= 'Contact Location',
    TITLE = 'Title',
    CONTACT = 'Contact',
    CONTACT_TYPE = 'Contact Type',
    CONTACT_STATUS = 'Status',
    CONTACT_ACCESS_LEVEL = 'Access Level',
}


export enum VENDOR_CONTACT_DATA_FILTERS {
    CONTACT_METHOD = 'Contact Method',
    CONTACT = 'Contact',
    CONTACT_NAME = 'Contact Name',
    TITLE = 'Title',
    CONTACT_TYPE = 'Contact Type',
    CONTACT_STATUS = 'Status',
    CONTACT_ACCESS_LEVEL = 'Access Level',
}

export enum DIRECTORY_CODE_DATA_FILTERS {
    CONTACT = 'Contact',
    CODE = 'Code',
}