export enum PARAMETERS_SETTINGS_COMPARISON_FILTERS {
    PARAMETERS_NAME = 'Name',
}
export enum SERVICE_ITEM_COMPARISON_FILTERS {
    SERVICE_ITEM_NAME = 'Name',
}
  
export enum PARAMETERS_SETTINGS_DATA_FILTERS {
    PARAMETERS_NAME = 'Name',
}
export enum SERVICE_ITEM_SETTINGS_DATA_FILTERS {
    SERVICE_ITEM_NAME = 'Name',
}
  