export enum COLLECTION_NAMES {
    VENDOR = 'Vendor',
    VENDLOR_LOCATION = 'VendorLocation',
    CONTACT = 'Contact',
    SERVICE_COMM = 'VendorLocationServiceComm',
    VENDOR_CONTACT = 'VendorContact',
    VENDOR_LOCATION_CONTACT = 'VendorLocationContact',
    ADDRESS_TYPE = 'AddressType',
    VENDOR_ADDRESS = 'VendorAddress',
    VENDOR_LOCATION_ADDRESS = 'VendorLocationAddress',
    VENDOR_LOCATION_DOCUMENT = 'VendorLocationDocument',
    VENDOR_DOCUMENT = 'VendorDocument',
}