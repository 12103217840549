import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  customHeight: {
    height: 'calc(100vh - 200px)',
  },
  excelErrorList:{
    fontSize: '18px'
  }
});
