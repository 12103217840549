export const apiUrls = {
  vendorSettings: 'api/VendorSettings',
  vendor:'api/Vendor',
  vendorLocation:'api/vendorLocation',
  vendorStatus:'api/VendorStatus',
  vendorContact:'api/VendorContact',
  vendorLocationContact:'api/VendorLocationContact',
  vendorLocationStatus:'api/VendorLocationStatus',
  vendorManagement:'api/vendormanagement',
  refData:'api/ReferenceData',
  vendorSettingsParameter:'api/Parameter',
  vendorSettingsUnits:'api/UOM',
  vendorSettingsHandlingFee:'api/HandlingFee',
  vendorSettingsCurrency: 'api/Currency',
  vendorSettingsServiceItemName:'api/ServiceItem',
  vendorSetingsServiceCategory:'api/ServiceCategory',
  vendorSettingsStatus:'api/ServiceItemPricingStatus',
  serviceItemPricing:'api/ServiceItemPricing',
  vendorMasterContact: 'api/Contact',
  vendorContactMethod:'api/ContactMethod',
  vendorContactType:'api/ContactType',
  vendorContactStatus:'api/ContactStatus',
  vendorAccessLevel:'api/AccessLevel',
  vendorContactCommunicationService:'api/ServiceComm',
  vendorContactUsageType:'api/ContactUsegeType',
  vendorLocationServiceCommunication: 'api/VendorLocationServiceComm',
  vendorAddressType: 'api/AddressType',
  vendorAddress: 'api/VendorAddress',
  vendorLocationAddress: 'api/VendorLocationAddress',
  vendorDocumentName: 'api/DocumentName',
  vendorDocumentStatus: 'api/DocumentStatus',
  vendorDocument: 'api/VendorDocument',
  vendorLocationDocument: 'api/VendorLocationDocument',
  vendorLevel: 'api/VendorLevel',
  certifiedMemberFeeSchedule: 'api/CertifiedMemberFeeSchedule',
  paymentOptions: 'api/PaymentOptions',
  creditAvailable: 'api/CreditAvailable',
  mainServicesOffered: 'api/MainServicesOffered',
  operationType: 'api/OperationType',
  operationalEssential: 'api/OperationalEssential',
  operationalInformation: 'api/OperationalInformation',
  createNewUser: '/api/v1/okta/users',
  vendorLocationOwnership: 'api/VendorLocationOwnership',
  vendorOwnership: 'api/VendorOwnership',
  hoursType: '/api/HoursType',
  scheduleType: '/api/HoursScheduleType',
  status: '/api/Status',
  vendorLocationHours: '/api/VendorLocationHours',
  a2GLocationType: 'api/A2GLocationType',
  vendorLocationA2G: 'api/VendorLocationA2G',
  vendorUser: 'api/VendorUser',
  groundServiceProvider: 'api/GroundServiceProvider',
  oktaGroups: '/api/v1/groups',
  vendorUserEmail: 'api/VendorUser/SendMail',
  approval:{
    merge:'api/Approval/Merge',
    reject:'api/Approval/Reject',
    getInfo:'api/Approval/GetInfo'
  },
  approvalSettings:{
    add:'api/ApprovalSetting/Add',
    update:'api/ApprovalSetting/Update',
    getById:'api/ApprovalSetting/GetById',
    getByEntityName:'api/ApprovalSetting/GetByEntityName',
    getAll:'api/ApprovalSetting/GetAll'
  },
  approvalData:{
    addList:'api/ApprovalData/AddList',
    add:'api/ApprovalData/Add',
    update:'api/ApprovalData/Update',
    getById:'api/ApprovalData/GetById',
    getByEntityId:'api/ApprovalData/GetByEntityId',
    getAllWithEntityGroup:'api/ApprovalData/GetAllWithEntityGroup'
  },
  approvalStatus: '/api/ApprovalStatus',
  vendorBank: 'api/VendorBank',
  pickUpLocation: 'api/PickUpLocation',
  amenities: 'api/Amenities',
  aircraftParkingOptions: 'api/AircraftParkingOptions',
  aircraftParkingDistanceFBO: 'api/AircraftParkingDistanceFBO',
  aircraftSpotAccommodation: 'api/AircraftSpotAccommodation',
  towbarScenarios: 'api/TowbarScenarios',
  internationalDepartureProcedures: 'api/InternationalDepartureProcedures',
  internationalArrivalProcedures: 'api/InternationalArrivalProcedures',
  availableFacilities: 'api/AvailableFacilities',
  luggageHandling: 'api/LuggageHandling',
  arrivalCrewPaxPassportHandling: 'api/ArrivalCrewPaxPassportHandling',
  disabilityAccommodations: 'api/DisabilityAccommodations',
  hangerAvailableUom: 'api/HangerAvailableUom',
  vendorLocationBank: 'api/VendorLocationBank',
  operationalInsight: 'api/OperationalInsight',
  orderManagementSoftware: 'api/OrderManagementSoftware',
  vendorLocationOrderManagement: 'api/VendorLocationOrderManagement',
  downloadTemplete: 'api/ServiceItemPricingBulkUpload',
  serviceItemPricingApproval: 'api/ServiceItemPricingApproval/GetByVendorLocationId',
  serviceItemPricingApproved: 'api/ServiceItemPricingApproval/Approve'
};
